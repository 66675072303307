import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'

import PuxTrumbowyg from '../../components/shared/pux-trumbowyg'
import Layout from '../../components/layout/layout'
import PuxMetaTags from '../../components/shared/PuxMetaTags'
import '../../sass/errorPage/error-page.scss'
import { PuxErrorPage } from '../../types/pux.types'
import { ReactComponent as PuxIllustration } from '../../img/warning-404.svg'
import PuxIllustrationBG from '../../img/x-404.svg'
import Cursor from '../img/cursor404.gif'

export const query = graphql`
  query {
    orchard {
      puxErrorPage(first: 1, where: { alias: { alias_contains: "404" }, localization: { culture: "en-US" } }) {
        content {
          html
        }
      }
    }
  }
`

const NotFoundPage: FunctionComponent<PuxErrorPage> = ({ data }) => {
  const page = data
  return (
    <Layout>
      <PuxMetaTags title="404: Not found" />
      <div
        className="error-page-background"
        style={{
          backgroundImage: `url(${PuxIllustrationBG})`,
        }}
      >
        <div className="pux-container">
          <div className="error-page">
            <div className="error-page-content">
              <PuxTrumbowyg content={page.orchard.puxErrorPage[0].content} />
            </div>
            <div className="error-page-illustration">
              <PuxIllustration />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
